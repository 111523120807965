import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { sendPassResetLink } from '../../utils/utils';
import './passreset.scss';

const PassReset = () => {

    const navigate = useNavigate();

    const defaultFormValues = {
        'username': '',
        'email': ''
    }

    const [form, setForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState({});

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
    };

    const handleReset = (e) => {
        e.preventDefault();

        const newErrors = {};

        if (form['email'] === '') {
            newErrors['email'] = 'required field';
        } 
        if (form['username'] === '') {
            newErrors['username'] = 'required field';
        }
        

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors(Object());
            sendPassResetLink(form, navigate, setErrors);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleReset(e);
        }
    }

    return(
        <Row>
            <Col>
                <Form className="passResetEmailForm">
                    <Form.Group className='mb-3' controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" 
                            placeholder="Enter username" 
                            value={form['username']} 
                            onChange={(e) => setField('username', e.target.value)}
                            onKeyPress={handleKeyPress}
                            isInvalid={ !!errors.username }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter your registered Email" 
                            value={form['email']} 
                            onChange={(e) => setField('email', e.target.value)}
                            onKeyPress={handleKeyPress}
                            isInvalid={ !! errors.email }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <ButtonToolbar className='mb-3' aria-label="buttons">
                        <ButtonGroup className='me-2' aria-label="cancel">
                            <Button variant="secondary" type="cancel" onClick={() => navigate('/')}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className='me-2' aria-label="submit">
                            <Button variant="primary" type="submit" onClick={handleReset}>
                                Reset
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Col>
        </Row>
    )
}

export default PassReset;