import { compare } from "../utils/utils";

export const setPresences = (store, users, callBackFunc, callBackVal) => {
    const newUsers = users;
    newUsers.sort(compare);
    store.setState(
        { users: newUsers }, 
        function () {
            if (callBackFunc !== 'undefined') {
                callBackFunc(callBackVal);
            }
        }
    );
};