export const setCreds = (store, username, is_staff, token, firstname, lastname, email, groups, location, dept, work_schedules) => {

    const newLogin = { 
        username: username, 
        is_staff: is_staff,
        token: token,
        firstname: firstname,
        lastname: lastname,
        email: email,
        location: location,
        dept: dept,
        groups: groups,
        work_schedules: work_schedules
    };
    store.setState({ login: newLogin });
};