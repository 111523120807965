import React, { useState, useEffect } from 'react';
import { Dropdown, Button, ButtonToolbar, Navbar, Nav } from 'react-bootstrap';
import useGlobal from "../../store";
import { useNavigate } from "react-router-dom";
import {capitalize} from "../../utils/utils";
import './toolbar.scss';


const Toolbar = () => {
    const [globalState, globalActions] = useGlobal();

    const navigate = useNavigate();

    const username = localStorage.getItem('username');
    const is_staff = localStorage.getItem('is_staff') == "true" ? true : false;
    const token = localStorage.getItem('token');
    const firstname = localStorage.getItem('firstname');
    const lastname = localStorage.getItem('lastname');
    const email = localStorage.getItem('email');
    const location = localStorage.getItem('location');
    const dept = localStorage.getItem('dept');
    const groups = localStorage.getItem('groups');
    const work_schedules = localStorage.getItem('work_schedules');

    if (username && !globalState.login.username) {
        globalActions.setCreds(username, is_staff, token, firstname, lastname, email, groups, location, dept, work_schedules);
    }

    const logout = (e) => {
        e.preventDefault();

        const storage = localStorage;

        storage.removeItem('username');
        storage.removeItem('is_staff');
        storage.removeItem('token');
        storage.removeItem('firstname');
        storage.removeItem('lastname');
        storage.removeItem('email');
        storage.removeItem('location');
        storage.removeItem('dept');
        storage.removeItem('groups');
        storage.removeItem('work_schedules');

        globalActions.setCreds(null, null, null, null, null, null, null, null, null, null, null);   
        navigate("/login");     
    }
    
    return(
        
        <div className="toolbar">
                <Navbar bg="light" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ButtonToolbar>
                            {globalState.login.username 
                                ?
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="user-actions">
                                        Hi, {capitalize(globalState.login.username)}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/edituser">Edit User</Dropdown.Item>
                                        {globalState.login.is_staff &&
                                            <Dropdown.Item href="/bulkcreate">Bulk Create</Dropdown.Item>
                                        }
                                        <Dropdown.Item onClick={e => logout(e)}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                : 
                                <Button variant="success" id="login-btn">Login</Button>                                
                            }   
                        </ButtonToolbar>
                    </Navbar.Collapse>
                </Navbar>
            </div>
    )
};

export default Toolbar;