import React, { useState } from "react";
import { Form, Button, Row, Col, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { Navigate, useNavigate } from "react-router-dom";
import { getToken, registerUser, depts } from '../../utils/utils';
import useGlobal from "../../store";
import { findFormErrors } from "../../utils/utils";
import './login.scss';

const Login = () => {
    const [globalState, globalActions] = useGlobal();

    const navigate = useNavigate();

    const defaultFormValues = {
        'username': '',
        'password': '',
        'firstname': '',
        'lastname': '',
        'birth_date': '',
        'birth_month': '',
        'company_start': '',
        'location': '',
        'dept': '',
        'work_schedules': [],
        'email': ''
    }

    // constants for birthday selects
    const monthDates = [...Array(31).keys()].map(x => x + 1);
    const monthNums = [...Array(12).keys()].map(x => x + 1);
    const locations = ['NZ', 'AUS'];

    const [form, setForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState({});

    const [registerChecked, setRegisterChecked] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();

        if (form['username'] !== '' && form['password'] !== '') {
            getToken(form['username'], form['password'], navigate, globalActions.setCreds, setErrors);
        }
    };

    const handleRegister = (e) => {
        e.preventDefault();

        const newErrors = findFormErrors(form);

        if ( Object.keys(newErrors).length > 0 ) {
            // we got errors
            setErrors(newErrors);
        } else {
            registerUser(form, navigate, globalActions.setCreds, setErrors);
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
    };

    if (globalState.login.username) {
        return <Navigate to="/" replace={true} />
    }

    return (
        
        <Row>
            <Col>
                <Form className="loginForm">
                    {registerChecked &&
                        <>
                            <Form.Group className='mb-3' controlId="firstname">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter first name" 
                                    value={form['firstname']} 
                                    onChange={(e) => setField('firstname', e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="lastname">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter last name" value={form['lastname']} onChange={(e) => setField('lastname', e.target.value)}  />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter email" 
                                    value={form['email']} 
                                    onChange={(e) => setField('email', e.target.value)} 
                                    isInvalid={ !! errors.email } 
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    }
                    <Form.Group className='mb-3' controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" 
                            placeholder="Enter username" 
                            value={form['username']} 
                            onChange={(e) => setField('username', e.target.value)}
                            isInvalid={ !!errors.username }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {registerChecked &&
                        <>
                        <Row className="mb-3" key="birtday">
                            <Form.Group as={Col} controlId="birth_date" key="birth_date">
                                <Form.Label>Birth DD</Form.Label>
                                <Form.Select
                                    value={form['birth_date']} 
                                    onChange={(e) => setField('birth_date', e.target.value)}
                                    isInvalid={ !! errors.birth_date } 
                                >
                                    <option></option>
                                    {monthDates.map(opt => (
                                        <option value={opt} key={opt}>{opt}</option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.birth_date}
                                </Form.Control.Feedback>
                                
                            </Form.Group>
                            <Form.Group as={Col} controlId="birth_month" key="birth_month">
                                <Form.Label>Birth MM</Form.Label>
                                <Form.Select
                                    value={form['birth_month']} 
                                    onChange={(e) => setField('birth_month', e.target.value)}
                                >
                                    <option></option>
                                    {monthNums.map(opt => (
                                        <option value={opt} key={opt}>{opt}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Form.Group className='mb-3' controlId="company_start" key="company_start">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={form['company_start']}
                                onChange={(e) => setField('company_start', e.target.value)}
                                isInvalid={ !! errors.company_start }
                            >

                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                {errors.company_start}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId="location" key="location">
                            <Form.Label>Country Employed</Form.Label>
                            <Form.Select
                                value={form['location']}
                                onChange={(e) => setField('location', e.target.value)}
                                isInvalid={ !!errors.location }
                            >
                                <option></option>
                                {locations.map(opt => (
                                    <option value={opt} key={opt}>{opt}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                {errors.location}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId="dept" key="dept">
                            <Form.Label>Department</Form.Label>
                            <Form.Select
                                value={form['dept']}
                                onChange={(e) => setField('dept', e.target.value)}
                                isInvalid={ !!errors.dept }
                            >
                                <option></option>
                                {depts.map(opt => (
                                    <option value={opt['dept']} key={opt['dept']}>{opt['name']}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                                {errors.dept}
                            </Form.Control.Feedback>
                        </Form.Group>
                        </>
                    }
                    <Form.Group className='mb-3' controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            placeholder="Password" 
                            value={form['password']} 
                            onChange={(e) => setField('password', e.target.value)}  
                            isInvalid={ !!errors.password }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='registerCheckbox'>
                        <Form.Check 
                            type="checkbox" 
                            label="Register new user" 
                            checked={registerChecked}
                            onChange={(e) => {setRegisterChecked(!registerChecked)}}
                        />
                        <Nav.Item>
                            <Nav.Link href="/passreset">Forgot password?</Nav.Link>
                        </Nav.Item>
                    </Form.Group>

                    <ButtonToolbar className='mb-3' aria-label="buttons">
                        <ButtonGroup className='me-2' aria-label="submit">
                        {registerChecked ?
                            <Button variant="primary" type="submit" onClick={handleRegister}>
                                Register
                            </Button>
                            :
                            <Button variant="primary" type="submit" onClick={handleLogin}>
                                Login
                            </Button>
                        }
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Col>
        </Row>
        
    )
}

export default Login;