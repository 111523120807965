import React, { useEffect, useState } from "react";
import DateCell from "../datecell/datecell";
import { ButtonGroup, Modal, Button, Dropdown } from 'react-bootstrap';
import AltDateCell from "../altdatecell/altdatecell";
import {capitalize, toggleSubscribe} from "../../utils/utils";
import useGlobal from "../../store";
import "./userrow.scss";

const UserRow = (props) => {
    const [globalState, globalActions] = useGlobal();
    const [subscribed, setSubscribed] = useState(false);

    useEffect(() => {
        // check if logged user's subscriptions contain this user
        globalState.users.map((user, idx) => {
            // if it's currently logged user
            if (user.username === globalState.login.username) {
                // loop through subscriptions and if any is for the username from props, it's subscribed
                user.employee.subscriptions.map((sub, idx) => {
                    if (sub.subscribed_to_name === props.user.username) {
                        setSubscribed(true);
                    }
                })
            }
        });
    }, [subscribed]);

    if (!props.user.employee) {
        return null;
    }

    const handleSubscribe = () => {
        const subscribe_to = props.user.username;
        const subscribe_by = globalState.login.username;
        const startDate = new Date(Math.min(...props.dates));
        const endDate = new Date(Math.max(...props.dates));

        toggleSubscribe(
            globalState.login.token, 
            startDate.toLocaleDateString('fr-CA'), 
            endDate.toLocaleDateString('fr-CA'), 
            subscribe_to,subscribe_by,
            globalActions.setPresences,
            setSubscribed
        );
    }

    const SubscribeButton = () => {
        if (!props.user.employee.restricted_to_location) {
            return null;
        }
        
        if (subscribed) {
            return(
                <a  href=""
                    className="btn-group"
                    onClick={(e) => {
                        e.preventDefault();
                        handleSubscribe(e);
                    }}
                >
                    <i className="bi bi-binoculars-fill" style={{ fontSize: 20 }}></i>
                    <span className="tooltip-text">Unsubscribe from changes</span>
                </a>
            )
        }
        return(
            <a  href=""
                className="btn-group"
                onClick={(e) => {
                    e.preventDefault();
                    handleSubscribe(e);
                }}
            >
                <i className="bi bi-binoculars" style={{ fontSize: 20 }}></i>
                <span className="tooltip-text">Subscribe to changes</span>
            </a>
        )
    }


    return (
        <div className="row wrapper">
            <div className="flex-row cell first" role="cell" key={props.user.username} >
                
                {capitalize(props.user.username)}

                <SubscribeButton></SubscribeButton>
                
            </div>
            
            {props.dates.map((date, idx) => (
                <AltDateCell 
                    date={date}
                    presences={props.user.presences}
                    work_schedules={props.user.work_schedules}
                    username={props.user.username}
                    is_staff={globalState.login.is_staff}
                    birthday={props.user.employee.birthday}
                    anniv_date={props.user.employee.anniv_date}
                    company_start={props.user.employee.company_start}
                    key={`datecell_${idx}`}
                    showAlert={props.showAlert}
                />
            ))}
        </div>
    )
}

export default UserRow;