import React, { useState, useEffect } from "react";
import { ButtonGroup, Modal, Button, Dropdown } from 'react-bootstrap';
import {createUpdatePresence, getMonday, getSunday, getDateArray, calcAge} from '../../utils/utils';
import moment from 'moment';
import useGlobal from "../../store";
import "./altdatecell.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCakeCandles } from '@fortawesome/free-solid-svg-icons';
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const AltDateCell = (props) => {

    const [globalState, globalActions] = useGlobal();

    const [startDate, setStartDate] = useState(getMonday(moment(props.date)));
    const [endDate, setEndDate] = useState(getSunday(moment(props.date)));

    const [show, setShow] = useState();
    const [presenceType, setPresence] = useState();
    const [modalPresenceType, setModalPresence] = useState();
    const [presenceConfirmed, setPresenceConfirmed] = useState();
    const [workingDay, setWorkingDay] = useState(true);


    const [showDropDown, setShowDropDown] = useState(props.is_staff);

    const groups = JSON.parse(globalState.login.groups);
    const [isMaster, setIsMaster] = useState(groups.some(e => e.name === 'master'));

    const dows = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']


    useEffect(() => {
        setPresence("");
        setModalPresence("");
        setStartDate(getMonday(moment(props.date)));
        setEndDate(getSunday(moment(props.date)));
        
        props.presences.forEach((pr) => {
            if (pr.date === props.date.toLocaleDateString('fr-CA')) {
                setPresence(pr.presence_type);
                setModalPresence(pr.presence_type);
                setPresenceConfirmed(pr.confirmed);
            }
        });
        
        // finds approparite work schedule and defines if it's a working day or not
        props.work_schedules.map((ws) => {
            
            if (Date.parse(props.date) >= Date.parse(ws.start_date) && Date.parse(props.date) <= Date.parse(ws.end_date)) {
                setWorkingDay(ws[dows[props.date.getDay()]]);

                if (! ws[dows[props.date.getDay()]]) {
                    setShowDropDown(false);
                }

                return true;
            } else {
                setWorkingDay(true);
            }

            return true;
        });

        if (props.is_staff === 'true') {
            setShowDropDown(true);
        } else if (globalState.login.username === props.username && workingDay) {
            setShowDropDown(true);
        }
    }, [presenceType, props, showDropDown, presenceConfirmed, workingDay]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleModalChange = (prType) => {
        const presenceInstance = {
            'username': props.username,
            'presences': [
                {
                    'date': props.date.toLocaleDateString('fr-CA'),
                    'presence_type': prType
                }
            ]
        }

        createUpdatePresence(globalState.login.token, startDate.toLocaleDateString('fr-CA'), endDate.toLocaleDateString('fr-CA'), presenceInstance, globalActions.setPresences, props.showAlert);
    };

    const handleSubmit = (e) => {
        setPresence(modalPresenceType);

        const presenceInstance = {
            'username': props.username,
            'presences': [
                {
                    'date': props.date.toLocaleDateString('fr-CA'),
                    'presence_type': modalPresenceType
                }
            ]
        }

        createUpdatePresence(globalState.login.token, startDate.toLocaleDateString('fr-CA'), endDate.toLocaleDateString('fr-CA'), presenceInstance, globalActions.setPresences, props.showAlert);
        handleClose();
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn-group"

        >
            {children}
            <i className="bi bi-pencil" style={{ fontSize: 20 }}></i>
        </a>
    ));


    return (
        <div className={`flex-row cell ${presenceType} ${presenceConfirmed ? 'confirmed' : 'projected'} ${workingDay ? '' : 'non-working'}`} role='cell'>

            {presenceType && workingDay ? 
                    <div className="prType">{presenceType}</div> 
                :
                    <div className="prType">&nbsp;&nbsp;</div>
            }

            {/* BIRTHDAY */}
            {props.birthday && ('0' + (props.date.getMonth() + 1).toString()).slice(-2) == props.birthday.slice(0,2) && ('0' + (props.date.getDate())).slice(-2) == props.birthday.slice(2,4) ?
                <FontAwesomeIcon className="bdCake" icon={faCakeCandles} />
                :
                <div className="prType">&nbsp;&nbsp;</div>
            }

            {/* ANNIVERSARY */}
            {props.anniv_date && ('0' + (props.date.getMonth() + 1).toString()).slice(-2) == props.anniv_date.slice(0, 2)
                 && ('0' + (props.date.getDate()).toString()).slice(-2) == props.anniv_date.slice(2, 4) ?
                <span className="anniv">{calcAge(props.date, props.company_start) + 'yrs'}</span>
                :
                null
            }
            

            {showDropDown === true && 
                <Dropdown onSelect={handleModalChange}>
                    <Dropdown.Toggle as={CustomToggle} variant="secondary" id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="PR">PR</Dropdown.Item>
                        <Dropdown.Item eventKey="AB">AB</Dropdown.Item>
                        <Dropdown.Item eventKey="AM">AM</Dropdown.Item>
                        <Dropdown.Item eventKey="PM">PM</Dropdown.Item>
                        <Dropdown.Item eventKey="WH">WH</Dropdown.Item>
                        <Dropdown.Item eventKey="OS">OS</Dropdown.Item>
                        <Dropdown.Item eventKey="PL">PL</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }               
        </div>
    )
}

export default AltDateCell;