import React from "react";
import globalHook from 'use-global-hook';
import { useNavigate, useLocation } from "react-router-dom";

import {setCreds} from "../actions/login";
import { setPresences } from "../actions/presence";
import { setUserDetails } from "../actions/userDetails";

const actions = {
    setCreds: setCreds,
    setPresences: setPresences,
    setUserDetails: setUserDetails
};

const initialState = {
    userDetails: {
        username: null, 
        firstname: null,
        lastname: null,
        location: null,
        dept: null,
        email: null
    },
    login: {
        username: null,
        token: null
    },
    users: [
        
    ]
};

export const useGlobal = globalHook(initialState, actions);

export const connect = Component => {
    return props => {
        let [state, actions] = useGlobal();
  
        const location = useLocation();

        state.location = location;

        let _props = { ...props, state, actions };

        return <Component {..._props} navigate={useNavigate()} />;
    };
}

export default useGlobal;
