import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useGlobal from "../../store";
import CalendarComponent from "../calendar/calendar";
import * as utils from '../../utils/utils';
import "./presence.scss";

const Presence = () => {
    const [globalState, globalActions] = useGlobal();

    if (!globalState.login.username) {
        return <Navigate to="/login" replace={true} /> 
    }
    return (
        <div key="presence-div" className="presence-container container">
            <CalendarComponent />
        </div>
    );
};

export default Presence;