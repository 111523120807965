import React from "react";
import { Nav } from 'react-bootstrap';
import './regsuccess.scss';

const RegSuccess = (props) => {

    return (
        <div className='container'>
            <p>Email confirmation has been sent, please check your Email to activate user or reset password</p>
            <Nav activeKey="/">
                <Nav.Item>
                    <Nav.Link href="/">Back to login</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    )
}

export default RegSuccess;