import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { useParams, navigate, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../utils/utils';

const NewPass = () => {
    const {username, confirmToken} = useParams();

    const navigate = useNavigate();

    const defaultFormValues = {
        'password': '',
        'confirmPassword': ''
    }

    const [form, setForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState({});

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
    };

    const resetPass = (e) => {
        e.preventDefault();

        const newErrors = {};

        if (form['password'] === '') {
            newErrors['password'] = 'required field';
        } 
        if (form['confirmPassword'] === '') {
            newErrors['confirmPassword'] = 'required field';
        } else if (form['password'] !== form['confirmPassword']) {
            newErrors['confirmPassword'] = 'password is not same';
        }      

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors(Object());
            resetPassword(username, confirmToken, form['password'], form['confirmPassword'], navigate, setErrors);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            resetPass(e);
        }
    }

    return (
        <Row>
            <Col>
                <Form className="passResetForm">
                    <Form.Group className='mb-3' controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" 
                            placeholder="Enter password" 
                            value={form['password']} 
                            onChange={(e) => setField('password', e.target.value)}
                            onKeyPress={handleKeyPress}
                            isInvalid={ !!errors.password }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="confirmPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" 
                            placeholder="Enter password again" 
                            value={form['confirmPassword']} 
                            onChange={(e) => setField('confirmPassword', e.target.value)}
                            onKeyPress={handleKeyPress}
                            isInvalid={ !!errors.confirmPassword }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <ButtonToolbar className='mb-3' aria-label="buttons">
                        <ButtonGroup className='me-2' aria-label="cancel">
                            <Button variant="secondary" type="cancel" onClick={() => navigate('/')}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className='me-2' aria-label="submit">
                            <Button variant="primary" type="submit" onClick={resetPass}>
                                Reset
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Col>
        </Row>
    )
}

export default NewPass;