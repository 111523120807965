import moment from 'moment';

const API_HOST = process.env.REACT_APP_API_URL;
const REACT_APP_PROTOCOL = process.env.REACT_APP_PROTOCOL
const HOSTNAME = window.location.hostname;
let PORT;

switch(process.env.NODE_ENV) {
    case 'development':
        PORT = '8000';
        break;
    case 'production':
        PORT = window.location.port;
        break;
    default:
        PORT = window.location.port;
}

const API_HOST_ADDRESS = REACT_APP_PROTOCOL + '://' + HOSTNAME + ':' + PORT;

export const credsLink = API_HOST_ADDRESS + '/api-token-auth/';
export const presentsLinks = API_HOST_ADDRESS + '/api/presences?';
export const registerLink = API_HOST_ADDRESS + '/api/register';
export const editUserLink = API_HOST_ADDRESS + '/api/edituser';
export const getUserLink = API_HOST_ADDRESS + '/api/userdetails?username=';
export const subscribeLink = API_HOST_ADDRESS + '/api/subscribe';
export const passResetSendLink = API_HOST_ADDRESS + '/api/send_pass_reset_email';
export const passResetLink = API_HOST_ADDRESS + '/api/reset_password';
export const bulkCreateLink = API_HOST_ADDRESS + '/api/bulk_create';

export const getUsers = (token, setUsers) => {
    fetch(bulkCreateLink, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => {
        if (res.status === 200) {
            return res.json()
        }
    })
    .then(result => {
        setUsers(result)
    })
    .catch(e => console.log(`utils.resetPassword: ${e.message}`));
}

export const submitBulkCreate = (token, form, navigate, setErrors) => {
    console.log(JSON.stringify(form));
    fetch(bulkCreateLink, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => {
        if (res.status === 201) {
            navigate('/')
        } else if (res.status === 406 || res.status === 412) {
            return res.json()
        }
    })
    .then(result => {
        if (result['field'] === 'username') {
            const newErrors = {
                'username': 'username is wrong'
            }
            setErrors(newErrors);
        } else if (result['field'] === 'start_date' || result['field'] === 'end_date') {
            const field = result['field'];
            const newErrors = {}
            newErrors[field] = 'date format is wrong'
            setErrors(newErrors);
        } else if (result['field'] === 'presence_type') {
            const newErrors = {
                'presence_type': 'presence_type is not acceptable'
            }
            setErrors(newErrors);
        } else if (result['field'] === 'end_date_earlier') {
            const newErrors = {
                'end_date': 'end_date is earlier than start_date'
            }
            setErrors(newErrors);
        }
    })
    .catch(e => console.log(`utils.resetPassword: ${e.message}`));
}

export const resetPassword = (username, token, password, confirmPassword, navigate, setErrors) => {
    fetch(passResetLink, {
        method: 'POST',
        body: JSON.stringify(
            {
                'username': username,
                'confirmToken': token,
                'password': password,
                'confirmPassword': confirmPassword
            }
        ),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        if (res.status === 401) {
            const newErrors = {
                'password': 'Token is invalid or expired. Please contact website administrator'
            }
            setErrors(newErrors);
        } else if (res.status === 400) {
            const newErrors = {
                'password': 'User not found, contact system administrator'
            }
            setErrors(newErrors);
        } else if (res.status === 202) {
            navigate("/login");
        } else {
            return res.json()
        }
    })
    .catch(e => console.log(`utils.resetPassword: ${e.message}`));
}

export const sendPassResetLink = (form, navigate, setErrors) => {
    fetch(passResetSendLink, {
        method: 'POST',
        body: JSON.stringify(
            {
                'username': form['username'],
                'email': form['email']
            }
        ),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        if (res.status === 400) {
            const newErrors = {
                'username': 'User - email combination does not exist'
            }
            setErrors(newErrors);
        } else if (res.status === 202) {
            navigate("/regsuccess");
        } else {
            return res.json()
        }
    })
    .then(res => {
        console.log(res);
    })
    .catch(e => console.log(`utils.sendPassResetLink: ${e.message}`));
}


export const editUser = (token, username, password, is_staff, firstname, lastname, email, location, dept, work_schedules, navigate, setCreds, setErrors) => {
    const credStorage = localStorage;

    fetch(editUserLink, {
        method: 'POST',
        body: JSON.stringify(
            {
                'username': username,
                'token': token,
                'password': password,
                'firstname': firstname,
                'lastname': lastname,
                'location': location,
                'dept': dept,
                'email': email,
                'work_schedules': work_schedules
            }
        ),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => {
        return res.json()
    })
    .then(result => {
        credStorage.setItem('username', result.username);
        credStorage.setItem('token', result.token);
        credStorage.setItem('firstname', result.firstname);
        credStorage.setItem('lastname', result.lastname);
        credStorage.setItem('email', result.email);
        credStorage.setItem('location', result.location);
        credStorage.setItem('dept', result.dept);
        credStorage.setItem('groups', JSON.stringify(result.groups));
        credStorage.setItem('work_schedules', JSON.stringify(result.work_schedules));

        setCreds(result.username, is_staff, result.token, result.firstname, result.lastname, result.email, JSON.stringify(result.groups), result.location, result.dept, JSON.stringify(result.work_schedules));
        navigate('/');
    })
    .catch(e => console.log(`utils.editUser: ${e.message}`));
}

export const registerUser = (form, navigate, setCreds, setErrors) => {
    const credStorage = localStorage;

    // username, password, firstname, lastname, email

    fetch(registerLink, {
        method: 'POST',
        body: JSON.stringify(
            form
        ),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(res => {
        if (res.status === 302) {
            const newErrors = {
                'username': 'this username already exists'
            }
            setErrors(newErrors);
        } else if (res.status === 201) {
            navigate("/regsuccess");
        } else if (res.status === 406) {
            res.json()
            .then(result => {
                if (result['field'] === 'email') {
                    const newErrors = {
                        'email': 'only @nlc.co.nz and @nlcpayroll.com emails allowed'
                    }
                    setErrors(newErrors);
                } else if (result['field'] === 'birth_date') {
                    
                    const newErrors = {
                        'birth_date': 'date/month incorrect'
                    }
                    setErrors(newErrors);
                } else if (result['field'] === 'company_start') {
                    const newErrors = {
                        'company_start': 'date incorrect'
                    }
                    setErrors(newErrors);
                } else if (result['field'] === 'location') {
                    const newErrors = {
                        'location': 'location incorrect'
                    }
                    setErrors(newErrors);
                } else if (result['field'] === 'dept') {
                    const newErrors = {
                        'dept': 'department incorrect'
                    }
                    setErrors(newErrors);
                }
                
            })
            
        } else {
            return res.json()
        }        
    })
    .then(result => {
        if(result.token) {
            credStorage.setItem('username', result.username);
            credStorage.setItem('token', result.token);
            credStorage.setItem('firstname', result.firstname);
            credStorage.setItem('lastname', result.lastname);
            credStorage.setItem('email', result.email);
            credStorage.setItem('location', result.location);
            credStorage.setItem('dept', result.dept);
            credStorage.setItem('groups', JSON.stringify(result.groups));
            credStorage.setItem('work_schedules', JSON.stringify(result.work_schedules));

            setCreds(result.username, result.token, result.firstname, result.lastname, result.email, JSON.stringify(result.groups), result.location, result.dept, result.work_schedules);
            navigate("/");
        } else {
            navigate("/");
        }
    })
    .catch(e => console.log(`utils.registerUser: ${e.message}`));
}

export const createUpdatePresence = (token, startDate, endDate, presence, setPresences, showAlert) => {
    fetch(presentsLinks + 'startDate=' + startDate.toString() + '&endDate='+endDate.toString(), {
        method: 'POST',
        body: JSON.stringify({'serialized_data': presence}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => {
        if (res.status === 406) {
            showAlert(true);
            return null;
        } else {
            return res.json()
        }
    })
    .then(result => {
        if (result === null) {
            
        } else {
            setPresences(result);
        }
    })
    .catch(e => console.log(`utils.createUpdatePresence: ${e.message}`));
}

export const getPresences = (token, startDate, endDate,setPresences) => {
    const getLink = presentsLinks + 'startDate=' + startDate.toString() + '&endDate='+endDate.toString();

    fetch(getLink, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => res.json())
    .then(result => {
        setPresences(result);
    })
    .catch(e => console.log(`utils.getPresences: ${e.message}`));
}

export const getToken = (username, password, navigate, setCreds, setErrors) => {
    const credStorage = localStorage;

    fetch(credsLink, {
        method: 'POST',
        body: JSON.stringify({ 'username': username, 'password': password }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then( res => {
            if (res.status === 400) {
                const newErrors = {
                    'password': 'Username or password is incorrect'
                }
                setErrors(newErrors);
            } else {
                return res.json();
            }            
        })
        .then( result => {
            if(result.token) {
                credStorage.setItem('username', username);
                credStorage.setItem('is_staff', result.is_staff);
                credStorage.setItem('token', result.token);
                credStorage.setItem('firstname', result.firstname);
                credStorage.setItem('lastname', result.lastname);
                credStorage.setItem('email', result.email);
                credStorage.setItem('location', result.location);
                credStorage.setItem('dept', result.dept);
                credStorage.setItem('groups', JSON.stringify(result.groups));
                credStorage.setItem('work_schedules', JSON.stringify(result.work_schedules));

                setCreds(username, result.is_staff, result.token, result.firstname, result.lastname, result.email, JSON.stringify(result.groups), result.location, result.dept, JSON.stringify(result.work_schedules));
                navigate("/");
            }
        })
        .catch(e => console.log(`utils.getToken: ${e.message}`));
}

export const toggleSubscribe = (token, startDate, endDate, subscribe_to, subscribe_by, setPresences, setSubscribed) => {
    fetch(subscribeLink, {
        method: 'POST',
        body: JSON.stringify({
            'startDate': startDate,
            'endDate': endDate,
            'subscribe_to': subscribe_to,
            'subscribe_by': subscribe_by
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    })
    .then(res => {
        return res.json()
    })
    .then(result => {
        setPresences(result.presences, setSubscribed, result.subscribed)
    })
    .catch(e => console.log(`utils.toggleSubscribe: ${e.message}`));
}

export const getMonday = (d) => {
    d = new Date(d);

    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6:1);

    return new Date(d.setDate(diff));
}

export const getSunday = (d) => {
    d = new Date(d);

    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6:1) + 6;

    return new Date(d.setDate(diff));
}

export const getDateArray = (start, end) => {

    var
      arr = new Array(),
      dt = new Date(start);
  
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
  
    return arr;
  
}

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getDateName = (date) => {
    return date.toLocaleDateString('en-US', { weekday: 'short' });
}

export const findFormErrors = (form) => {
    const {username, password, firstname, lastname, email} = form;
    const newErrors = {};
    
    if ( !username || username === '') newErrors.username = 'Required field'
    else if ( username.length > 30 ) newErrors.username = 'username has to be less than 30 characters'
    else if ( !password || password === '' ) newErrors.password = 'password is required'
    else if ( !email || email === '' ) newErrors.email = 'Email is required'
    else if ( !email.includes('@') ) newErrors.email = 'Not valid format'

    return newErrors
}

export const compare = ( a, b ) => {

    if (a.employee.restricted_to_location) {
        return 1;
    }

    if (b.employee.restricted_to_location) {
        return -1;
    }

    if ( a.username.toLowerCase() < b.username.toLowerCase() ){
        return -1;
    }
    if ( a.username.toLowerCase() > b.username.toLowerCase() ){
        return 1;
    }
    return 0;
}

export const calcAge = (date, company_start_str) => {
    const today_date = moment(date.toDateString());
    const company_start = moment(company_start_str);
    const diff = today_date.diff(company_start, 'years');

    return diff;
}

export const depts = [
                        {'name': 'NZ Bureau', 'dept': 'nzb'},
                        {'name': 'Aus Bureau', 'dept': 'aub'},
                        {'name': 'Consulting', 'dept': 'con'},
                        {'name': 'Developers', 'dept': 'dev'},
                        {'name': 'Admin', 'dept': 'adm'},
                    ]