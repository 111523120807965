import logo from './logo.svg';
import './App.scss';

import Presence from "./components/presence/presence";
import Login from "./components/login/login";
import Toolbar from "./components/toolbar/toolbar";
import EditUser from "./components/edituser/edituser";
import RegSuccess from "./components/regsuccess/regsuccess";
import PassReset from "./components/passreset/passreset";
import NewPass from "./components/newpass/newpass";
import BulkCreate from "./components/bulkCreate/bulkCreate";
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

import { Container } from 'react-bootstrap';

function App() {
  return (
    <Container>
        <BrowserRouter>
            <Toolbar />
            <Routes>
                <Route path="/" element={<Presence />} />
                <Route path="/login" element={<Login />} />
                <Route path="/edituser" element={<EditUser />} />
                <Route path="/regsuccess" element={<RegSuccess />} />
                <Route path="/passreset" element={<PassReset />} />
                <Route path="/bulkcreate" element={<BulkCreate />} />
                <Route path='newpass/:username/:confirmToken' element={<NewPass />} />                
            </Routes>
        </BrowserRouter>
    </Container>
  );
}

export default App;
