import React, { useEffect, useState } from "react";
import {useGlobal, connect} from "../../store";
import {getMonday, getSunday, getDateArray, getDateName} from "../../utils/utils";
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import * as utils from '../../utils/utils';
import UserRow from "../userrow/userrow";
import moment from 'moment';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import "./calendar.scss";
import 'react-calendar/dist/Calendar.css';

const CalendarComponent = () => {
    const [globalState, globalActions] = useGlobal();

    const [users, setUsers] = useState();

    const [startDate, setStartDate] = useState(getMonday(moment()));
    const [endDate, setEndDate] = useState(getSunday(moment()));

    const [showDropdown, setShowDropdown] = useState(false);

    const [isShowingAlert, setShowingAlert] = useState(false);

    useEffect(() => {
        utils.getPresences(globalState.login.token, startDate.toLocaleDateString('fr-CA'), endDate.toLocaleDateString('fr-CA'), globalActions.setPresences);
    }, []);

    useEffect(() => {
        setUsers(globalState.users);
    }, [users]);

    const showAlert = () => {
        setShowingAlert(true);
    }

    const moveDates = (direction, exactDate) => {
        let newStart = new Date(getMonday(moment()));
        let newEnd = new Date(getSunday(moment()));

        if (typeof exactDate !== "undefined") {
            newStart = new Date(getMonday(exactDate));
            newEnd = new Date(getSunday(exactDate));
        }
        else if ( direction === 'back' ) {
            newStart = new Date(moment(startDate).subtract(7, 'day'));
            newEnd = new Date(moment(endDate).subtract(7, 'day'));
        } else if ( direction === 'forward' ) {
            newStart = new Date(moment(startDate).add(7, 'day'));
            newEnd = new Date(moment(endDate).add(7, 'day'));
        } 

        setStartDate(newStart);
        setEndDate(newEnd);
        utils.getPresences(globalState.login.token, newStart.toLocaleDateString('fr-CA'), newEnd.toLocaleDateString('fr-CA'), globalActions.setPresences);
    }

    const chooseDate = (value) => {
        setShowDropdown(false);
        moveDates('', value);        
    }

    const toggleDropdown = (e) => {
        setShowDropdown(!showDropdown);
    }


    return (
        <div className='calendar'>
            <div className={`alert alert-success ${isShowingAlert ? 'alert-shown' : 'alert-hidden'}`}
                onTransitionEnd={() => setShowingAlert(false)}
                key='alert'
            >
                <strong>Not allowed for a future date</strong>
            </div>
            {globalState.users.length > 0 ?
                <div key='cal'>
                    <div className='dates-nav' key='date-nav'>
                        <h2>{startDate.toLocaleDateString('fr-CA')} - {endDate.toLocaleDateString('fr-CA')}</h2>
                        <ButtonGroup className="btn-grp" aria-label="First group">
                            <p variant="secondary" onClick={() => moveDates('back')}><i className="bi bi-arrow-left" style={{ fontSize: 30 }}></i></p>
                            <p variant="secondary" onClick={() => moveDates('forward')}><i className="bi bi-arrow-right" style={{ fontSize: 30 }}></i></p>
                            <p variant='secondary' className='today' onClick={() => moveDates()}>today</p>
                        </ButtonGroup>
                        <Dropdown className="date-picker" show={showDropdown} onToggle={toggleDropdown} autoClose="outside">
                            <Dropdown.Toggle  id="calendar-dropdown">
                                <FontAwesomeIcon className="bdCake" icon={faCalendar} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Calendar onChange={chooseDate} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="dates-container" role="table" key='dates-container'>
                        <div className="flex-table header" role="rowgroup">
                            <div className="flex-row first">Name</div>
                            {getDateArray(startDate, endDate).map((date, idx) => (
                                <div className="flex-row" role="columnheader" key={date + idx}>
                                    <div>{date.toLocaleDateString('fr-CA')}</div>
                                    <div>{getDateName(date)}</div>
                                </div>                                      
                            ))}
                        </div>
                        {utils.depts.map((dept, idx) => {
                            return (
                                <div className='dept-container' key={dept['dept']}>
                                    <h4>{dept['name']}</h4>

                                    {globalState.users.map((user, idx) => {
                                        if (dept['dept'] === user.employee.dept) {
                                            return (
                                                <div className="flex-table row" role="rowgroup" key={`userrow_${idx}`}>
                                                    <UserRow user={user} dates={getDateArray(startDate, endDate)} showAlert={showAlert} />
                                                </div>
                                            )
                                        }
                                    })}

                                </div>
                            )
                        })}                           

                    </div>
                        

                    <p className='legend PR'><span>PR</span> - Present in the office</p>
                    <p className='legend WH'><span>WH</span> - Work from Home</p>
                    <p className='legend AB'><span>AB</span> - Absent</p>
                    <p className='legend AM'><span>AM</span> - Absent AM</p>
                    <p className='legend PM'><span>PM</span> - Absent PM</p>
                    <p className='legend OS'><span>OS</span> - Offsite</p>
                    <p className='legend PL'><span>PL</span> - Parental</p>
                </div>
            :
            <div>Please log out and log back in again</div>
            
            }
            
        </div>
    );
};


export default CalendarComponent;