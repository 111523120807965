import React, { useState, useEffect } from "react";
import {useGlobal, connect} from "../../store";
import { Navigate, useNavigate } from "react-router-dom";
import {getMonday, getSunday, getDateArray, getDateName, getUsers} from "../../utils/utils";
import moment from 'moment';
import * as utils from '../../utils/utils';
import { Form, Button, Row, Col, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import './bulkCreate.scss';

const BulkCreate = (props) => {

    const [globalState, globalActions] = useGlobal();
    const navigate = useNavigate();

    const defaultFormValues = {
        'username': '',
        'start_date': '',
        'end_date': '',
        'presence_type': ''
    }

    const defaultPresenceTypes = [
        {'type': 'PR', 'desc': 'Present in the office'},
        {'type': 'AB', 'desc': 'Absent'},
        {'type': 'WH', 'desc': 'Work from Home'},
        {'type': 'OS', 'desc': 'Offsite'},
        {'type': 'PL', 'desc': 'Parental'}
    ];

    const [form, setForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [presenceTypes, setPresenceTypes] = useState(defaultPresenceTypes)

    useEffect(() => {
        utils.getUsers(globalState.login.token, setUsers);
    }, []);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        utils.submitBulkCreate(globalState.login.token, form, navigate, setErrors);
    }


    return (
        <Row>
            <Col>
                <Form className="bulkCreate">
                    <Form.Group className='mb-3' controlId="username" key="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Select
                            value={form['username']}
                            onChange={(e) => setField('username', e.target.value)}
                            isInvalid={ !!errors.username }
                        >
                            <option></option>
                            <option value='all' key='all'>All</option>
                            {users.map(user => (
                                <option value={user.username} key={user.username}>{user.username} - {user.email}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="start_date" key="start_date">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={form['start_date']}
                            onChange={(e) => setField('start_date', e.target.value)}
                            isInvalid={ !!errors.start_date }
                        >

                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {errors.start_date}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="end_date" key="end_date">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={form['end_date']}
                            onChange={(e) => setField('end_date', e.target.value)}
                            isInvalid={ !!errors.end_date }
                        >

                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {errors.end_date}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="presence_type" key="presence_type">
                        <Form.Label>Presence Type</Form.Label>
                        <Form.Select
                            value={form['presence_type']}
                            onChange={(e) => setField('presence_type', e.target.value)}
                            isInvalid={ !!errors.presence_type }
                        >
                            <option></option>
                            {presenceTypes.map(prType => (
                                <option value={prType.type} key={prType.type}>{prType.type} - {prType.desc}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {errors.presence_type}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <ButtonToolbar className='mb-3' aria-label="buttons">
                        <ButtonGroup className='me-2' aria-label="submit">
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className='me-2' aria-label="cancel">
                            <Button variant="secondary" type="cancel" onClick={() => navigate('/')}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Col>
        </Row>
    )
}

export default BulkCreate;