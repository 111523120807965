import React, { useState } from "react";
import { Form, Button, Row, Col, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Navigate, useNavigate } from "react-router-dom";
import { getToken, editUser } from '../../utils/utils';
import { findFormErrors, depts } from "../../utils/utils";
import useGlobal from "../../store";
import './edituser.scss';

const EditUser = (props) => {
    const [globalState, globalActions] = useGlobal();

    const navigate = useNavigate();

    const defaultFormValues = {
        'username': globalState.login.username,
        'password': '',
        'firstname': globalState.login.firstname,
        'lastname': globalState.login.lastname,
        'location': globalState.login.location.toUpperCase(),
        'dept': globalState.login.dept,
        'email': globalState.login.email,
        'work_schedules': JSON.parse(globalState.login.work_schedules)
    }

    const locations = ['NZ', 'AUS'];

    const dows = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const [form, setForm] = useState(defaultFormValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = findFormErrors(form);

        if ( Object.keys(newErrors).length > 0 ) {
            // we got errors
            setErrors(newErrors);
        } else {
            editUser(globalState.login.token, form['username'], form['password'], globalState.is_staff, form['firstname'], form['lastname'], form['email'], form['location'], form['dept'], form['work_schedules'], navigate, globalActions.setCreds, setErrors);
        }
    }

    const handleCheckboxes = (e) => {

        const newWorkSchedules = form['work_schedules'];
        newWorkSchedules[0][e.target.value] = !newWorkSchedules[0][e.target.value];

        setField('work_schedules', newWorkSchedules);
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if ( !!errors[field] ) setErrors({
            ...errors,
            [field]: null
        })
    };

    return (
        <Row>
            <Col>
                <Form className="loginForm">
                    <Form.Group className='mb-3' controlId="firstname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter first name" 
                            value={form['firstname']} 
                            onChange={(e) => setField('firstname', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="lastname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter last name" value={form['lastname']} onChange={(e) => setField('lastname', e.target.value)}  />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter email" 
                            value={form['email']} 
                            onChange={(e) => setField('email', e.target.value)} 
                            isInvalid={ !! errors.email } 
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" 
                            placeholder="Enter username" 
                            value={form['username']} 
                            onChange={(e) => setField('username', e.target.value)}
                            isInvalid={ !!errors.username }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.username}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="location" key="location">
                        <Form.Label>Country Employed</Form.Label>
                        <Form.Select
                            value={form['location']}
                            onChange={(e) => setField('location', e.target.value)}
                            isInvalid={ !!errors.location }
                        >
                            <option></option>
                            {locations.map(opt => (
                                <option value={opt} key={opt}>{opt}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {errors.location}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {form['work_schedules'] != null && form['work_schedules'].length > 0 &&
                        <Form.Group className='mb-3' controlId="work_schedule" key='work_schedule'>
                            <Form.Label>Work Schedule</Form.Label>
                            {dows.map((dow, idx) => (
                                <Form.Check
                                    checked={form['work_schedules'][0][dow]}
                                    type="checkbox"
                                    id={idx}
                                    label={dow}
                                    value={dow}
                                    onChange={handleCheckboxes}
                                    key={idx}
                                />
                            ))}
                        </Form.Group>
                    }
                    
                    {/* <Form.Group className='mb-3' controlId="dept" key="dept">
                        <Form.Label>Department</Form.Label>
                        <Form.Select
                            value={form['dept']}
                            onChange={(e) => setField('dept', e.target.value)}
                            isInvalid={ !!errors.location }
                        >
                            <option></option>
                            {depts.map(opt => (
                                <option value={opt['dept']} key={opt['dept']}>{opt['name']}</option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {errors.location}
                        </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group className='mb-3' controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            placeholder="Password" 
                            value={form['password']} 
                            onChange={(e) => setField('password', e.target.value)}  
                            isInvalid={ !!errors.password }
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <ButtonToolbar className='mb-3' aria-label="buttons">
                        <ButtonGroup className='me-2' aria-label="submit">
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className='me-2' aria-label="cancel">
                            <Button variant="secondary" type="cancel" onClick={() => navigate('/')}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </Form>
            </Col>
        </Row>
    )

}

export default EditUser;